/* eslint-disable jsx-a11y/label-has-associated-control */
// comment to trigger build
/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { Form, Main } from '@leshen/ui'

import { graphql } from 'gatsby'

import { useMapi } from '@leshen/gatsby-theme-leshen'

import {
  mapSections,
  mapRichTextHero,
} from '@leshen/gatsby-theme-contentful/utils/determiners'

const { EmilyClient } = require('@clearlink/emily')

const DprFormPage = ({ data }) => {
  const {
    contentfulPage: { sections, hero, spanish },
  } = data

  const heroOptions = {
    mapper: mapRichTextHero,
    isImageCritical: true,
    spanish,
  }

  const sectionOptions = {
    spanish,
  }

  const sectionsBeforeForm = sections.slice(0, 5)

  const sectionsAfterForm = sections.slice(5)

  const { requestId, sessionPromoCode } = useMapi()

  const [showSubmissionMessage] = useState(false)

  const { Meta_Description, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  let emilyEndpoint = 'https://leads.dev.aws.clear.link'

  if (process.env.LOCATION === 'production') {
    emilyEndpoint = 'https://leads.clear.link/'
  }

  return (
    <Layout
      data={passedData}
      main={
        <Main>
          {mapSections(hero, heroOptions)}

          {/* Render the rest of the sections from Contentful like normal */}
          {sectionsBeforeForm &&
            sectionsBeforeForm.map((section) =>
              mapSections(section, sectionOptions)
            )}

          <div className="dpr-form-container">
            <Form
              formId={null}
              formSource="eloqua"
              submitButtonText="Submit"
              submitButtonColor="primary"
              submitButtonOutlined={false}
              submitButtonGradient={false}
              inlineButton={false}
              formName="stephen-king-form-component"
              onSubmit={async ({
                values,
                setShowSubmissionMessage,
                setShowSubmissionErrorMessage,
              }) => {
                const mergedValues = {
                  ...values,
                  brand: 'QF',
                  elqFormName: 'paid_to_watch_stephen_king_movies',
                  origin: 'https://www.getquantumfiber.com/',
                  domain: 'https://www.getquantumfiber.com/',
                  promo_code: sessionPromoCode || 159760,
                  request_id: requestId,
                  source: 'paid_to_watch_stephen_king_movies',
                  social_platform: values.social_platform.value,
                }

                try {
                  const emilyClient = new EmilyClient(emilyEndpoint)

                  const response = await emilyClient.submit(mergedValues)

                  if (response.status === 200) {
                    setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                  } else {
                    setShowSubmissionMessage(false)
                  }
                  setShowSubmissionMessage(true)
                } catch (error) {
                  // If an error occurs:
                  console.error('Submission error:', error)
                  setShowSubmissionMessage(false)
                  setShowSubmissionErrorMessage(true)
                }
              }}
              emilyTCPAUpdate={() => {}}
              steps={null}
              showSubmissionMessage={showSubmissionMessage}
              fields={[
                {
                  name: 'first_name',
                  type: 'text',
                  label: 'First Name*',
                  required: true,
                },
                {
                  name: 'last_name',
                  type: 'text',
                  label: 'Last Name*',
                  required: true,
                },
                {
                  name: 'age',
                  type: 'checkbox',
                  required: true,
                  options: [
                    {
                      name: 'adult_age',
                      value: 'adult_age',
                      displayName:
                        'Are you 18 years of age or older and a US citizen?*',
                      label:
                        'Are you 18 years of age or older and a US citizen?*',
                    },
                  ],
                },
                {
                  name: 'zip_postal',
                  type: 'text',
                  label: 'Zip Code*',
                  required: true,
                },
                {
                  name: 'email_address',
                  type: 'email',
                  label: 'Email Address*',
                  required: true,
                },
                {
                  name: 'phone_number',
                  type: 'tel',
                  label: 'Phone Number*',
                  required: true,
                },
                {
                  name: 'write_question',
                  type: 'text',
                  label:
                    'What does Jack write over and over on his typewriter?*',
                  required: true,
                },
                {
                  name: 'occupation',
                  type: 'text',
                  label: 'Occupation/Job:',
                },
                {
                  name: 'x_handle',
                  type: 'text',
                  label: 'X Handle:',
                },
                {
                  name: 'instagram_handle',
                  type: 'text',
                  label: 'Instagram Handle',
                },
                {
                  name: 'tiktok_handle',
                  type: 'text',
                  label: 'TikTok Handle',
                },
                {
                  name: 'social_platform',
                  type: 'singleSelect',
                  label: 'Which social media app do you use the most?*',
                  placeholderText: 'Select one',
                  required: true,

                  options: [
                    {
                      name: 'tiktok',
                      value: 'tiktok',
                      displayName: 'TikTok',
                      label: 'TikTok',
                    },
                    {
                      name: 'instagram',
                      value: 'instagram',
                      displayName: 'Instagram',
                      label: 'Instagram',
                    },
                    {
                      name: 'X',
                      value: 'X',
                      displayName: 'X',
                      label: 'X',
                    },
                  ],
                },
                {
                  name: 'youtube_link',
                  type: 'text',
                  label: 'Link to YouTube video submission:',
                  placeholderText:
                    'https://www.youtube.com/watch?v=your-video-id',
                },
              ]}
              isMultiStep={false}
              heading={null}
              subheading={null}
              tcpaCheckbox={false}
              formDisclaimerPosition="Below Submit Button"
              disclaimer={{
                brandy: {
                  text: 'Disclaimer: By clicking “Submit Your Application,” you and any other subscriber or user of these phone number(s) and email(s) consent to receive emails, phone calls, and/or SMS messages via automated technology, and/or pre-recorded and artificial voice messages from getquantumfiber.com and its business partners at the number(s) and email addresses provided about the products and services we offer. Your consent is not required as a condition of purchase. You also agree to our site&apos;s privacy policy and terms of use.',
                  symbol: '',
                },
              }}
              initialStepValues={{}}
              smartyStreetsWebsiteKey={null}
              addressQualificationCode={null}
              spanish={false}
              requestId={null}
            />
          </div>
          {sectionsAfterForm &&
            sectionsAfterForm.map((section) =>
              mapSections(section, sectionOptions)
            )}
        </Main>
      }
    />
  )
}

export default DprFormPage

export const query = graphql`
  query DprFormPageQuery($id: String!, $pagePath: String) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetquantumfiberAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }

    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "87ee343a-7023-5a89-a4cb-0790ad2ab2e7" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "acff8862-7da3-5e17-9f83-5a564d73a494" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
